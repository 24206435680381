<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import {paginationHelper} from "@/helpers/pagination-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";

/**
 * Investor List Component
 */
export default {
  computed: {
    paginationHelper() {
      return paginationHelper
    }
  },
  components: {
    CustomCardSubtitle,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false
      },

      inputPage: "",

      investors: null,
      investor: {
        name: "",
        email: ""
      },

      showModal: false
    };
  },

  validations: {
    investor: {
      name: { required },
      email: { required }
    }
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('investors.title'),
          active: true
        }
      ]
    },

    getFields() {
      return [
        { key: "name", label: this.$t('investors.table.name') },
        { key: "email", label: 'Email' },
        { key: "action", label: this.$t('table.actions') }
      ]
    },

    async loadInvestors() {
      let page = this.table.currentPage - 1;
      if (page > 0) {
        page = this.table.currentPage * this.table.perPage - this.table.perPage;
      }

      const result = await axios.get(`/investor/list`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": this.table.perPage
        }
      });

      this.investors = result.data
      await this.loadInvestorsCount()

      if (this.mounted) {
        this.$refs.table.refresh()
      }

      return this.investors;
    },

    async loadInvestorsCount() {
      const result = await axios.get(`/investor/count`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
      });

      this.table.totalRows = result.data;
      this.table.rows = result.data;
    },

    deleteInvestor(investor) {
      this.submitted = true;

      swalHelper.yesOrNoWithSubTitle(() => {
        axios.delete(`/investor`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {"id": investor.id}
        }).then(() => {
          this.submitted = false;
          this.$bvToast.toast(this.$t('investors.delete.success'), {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });

          this.investors = this.investors.filter(element => element.id !== investor.id)
          this.$refs.table.refresh()
        }).catch((error) => {
          errorCatcher.catchErrors(error)
          this.submitted = false;
        })
      }, false)
    },

    createInvestor() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const json = JSON.stringify(this.investor);

      axios.put(`/investor`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast(this.$t("investors.create.success"), {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.investors.push(this.investor)
        this.$refs.table.refresh()

        this.hideModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.investor = {
        name: "",
        email: ""
      }
    },

  },

  async created() {
    try {
      await this.loadInvestors()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('investors.title')" :items="getItems()" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <a class="btn btn-success mb-2" @click="showModal = true">
                  <i class="mdi mdi-plus mr-2"></i> {{ $t('investors.create.button') }}
                </a>
              </div>
            </div>

            <template v-if="investors">
              <div class="py-4">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.show') }}&nbsp;
                        <b-form-select v-model="table.perPage" size="sm" :options="table.pageOptions"></b-form-select>&nbsp;{{ $t('table.entries') }}
                      </label>
                    </div>
                  </div>

                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        {{ $t('table.search') }}
                        <b-form-input
                            v-model="table.filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                            @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-right">
                      <label class="d-inline-flex align-items-center mx-2">
                        <b-form-input v-model="inputPage" class="form-control form-control-sm ml-2" />
                      </label>
                      <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, inputPage)">{{ $t('table.go-to-page') }}</b-button>
                    </div>
                  </div>
                </div>

                <div class="row py-3">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <b-table

                      ref="table"
                      :items="loadInvestors"
                      :fields="getFields()"
                      responsive="sm"
                      :per-page="table.perPage"
                      :current-page="table.currentPage"
                      :sort-by.sync="table.sortBy"
                      :sort-desc.sync="table.sortDesc"


                      :empty-text="$t('message.no-elements')"
                      :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                    <template v-slot:cell(action)="{ item }">
                      <a @click="deleteInvestor(item)" class="clickable-element text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a>
                    </template>

                  </b-table>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="float-left">
                      <p>{{ $t('table.entries-footer', { 'amount': paginationHelper.getElementsAmount(table), 'elements': paginationHelper.getElementsCount(table), 'all': table.totalRows }) }}</p>
                    </div>

                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="text-center py-3">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <b-modal
        id="modal-1"
        :scrollable="true"
        v-model="showModal"
        :title="$t('investors.create.title')"
        title-class="font-18"
        hide-footer
        @hide="hideModal"
        @esc="hideModal">
      <form>
        <div class="form-group">
          <label for="name">{{  $t('investors.table.name') }}</label>
          <input id="name" v-model="investor.name" :class="{ 'is-invalid': submitted && $v.investor.name.$error }" class="form-control" type="text"/>
          <div v-if="submitted && !$v.investor.name.required" class="invalid-feedback">{{ $t('message.required') }}</div>
        </div>

        <div class="form-group">
          <label for="email">{{  'Email' }}</label>
          <input id="email" v-model="investor.email" :class="{ 'is-invalid': submitted && $v.investor.email.$error }" class="form-control" type="text"/>
          <div v-if="submitted && !$v.investor.email.required" class="invalid-feedback">{{ $t('message.required') }}</div>
        </div>

        <div class="text-right">
          <b-button @click="createInvestor" variant="success">{{ $t('investors.create.button') }}</b-button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

  </Layout>
</template>